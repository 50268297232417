<template>
    <b-card>
        <validation-observer ref="simpleRules">
            <b-form>
                <!-- Section Informations -->
                <h4> {{$t('Fuelling Information')}} </h4>
                <hr style="margin-bottom: 16px;">
                <b-row>
                    <!-- Field: Import File -->
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('Fuelling')"  label-for="fuelling">
                            <validation-provider #default="{ errors }" name="fuelling" rules="required">
                                <b-form-file v-model="file1" accept=".txt" :state="Boolean(file1)" :placeholder="placeholderFile" drop-placeholder="Drop a file here"></b-form-file>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                     <!-- Btn: Save Submit -->
                     <b-col cols="12">
                        <b-button variant="primary" type="button" @click.prevent="validationForm">
                            {{$t('Submit')}}
                        </b-button>
                        
                    </b-col>

                                  
                </b-row>
               
            </b-form>
        </validation-observer>
    </b-card>
    </template>
    
    <script>
    import {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
    } from 'bootstrap-vue'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BFormFile
    } from 'bootstrap-vue'
    import {
        ref,
        onUnmounted
    } from '@vue/composition-api'
    import router from '@/router'
    import store from '@/store'
    import managementStoreModule from '../managementStoreModule'
    
    import {
        VueAutosuggest
    } from 'vue-autosuggest'
    
    //import Cleave from 'vue-cleave-component'
    import Cleave from 'cleave.js';
    //import 'cleave.js/dist/addons/cleave-phone.br'
    
    import vSelect from 'vue-select'
    
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from '@validations'
    
    export default {
    
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
    
            ValidationProvider,
            ValidationObserver,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            BCardText,
            BFormTextarea,
            BFormFile,
    
            VueAutosuggest,
            Cleave,
            vSelect
    
        },
        data() {
            return {
                myId: router.currentRoute.params.id,
                required,
                email,
                confirmed,
                url,
                between,
                alpha,
                integer,
                password,
                min,
                digits,
                alphaDash,
                length,

                file1: null,

                placeholderFile: this.$t('Choose a file or drop it here')
    
                  
             
            }
        },
    
        methods: {
    
             
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        store.dispatch('app-management/importFuelling', this.file1)
                    .then(response => {
                        this.msgDialog('Successful Fuelling import', 'success')
                        
                        router.push({
                            name: 'list-fuelling'
                        })
                    })
                    .catch(error => {
                        this.msgDialog('FAILURE to import Fuelling', 'error')
                        console.log(error)
                    })
                    }
                })
            },
             
            msgDialog(msg, icon) {
                this.$swal({
                    position: 'top-end',
                    icon: icon,
                    title: msg,
                    showConfirmButton: false,
                    timer: 1500 * 2,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
    
                    buttonsStyling: false,
                })
            },
    
    
        },
        setup() {
            const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'

            // Register module
            if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
            })
    
             
            return {
                MANAGEMENT_APP_STORE_MODULE_NAME,
                
            }
        },
    }
    </script>
    
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-autosuggest.scss';
    </style>
    
    <style>
    
    </style>
    